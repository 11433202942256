.popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: get-opacity($color-oscuro, 0.5);
    animation: fade 0.5s ease-in-out 0.2s both;
  
    &__container {
      position: relative;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-width: 320px;
      max-width: 360px;
      max-height: 580px;
      animation: fade 0.5s ease-in-out 0.5s both;
    }
  
    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      animation: fade 0.5s ease-in-out 0.8s both;
  
      button {
        padding: $space $space;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        color: $color-primario;
        background-color: $color-secundario;
        @include font-size(30px);
  
        &:hover {
          opacity: 0.9;
        }
      }
    }
  
    &__image {
      width: 100%;
      height: auto;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  
    @include media(tablet) {
      &__container {
        width: 90%;
        height: 90%;
        max-width: 438px;
        max-height: 760px;
      }
  
      &__image {
        width: 100%;
      }
    }
  
    @include media(desktop-s) {
      &__container {
        position: relative;
        display: block;
        width: auto;
        height: 80%;
        max-width: none;
        max-height: none;
      }
  
      &__header {
        position: absolute;
  
        button {
          @include font-size(45px);
        }
      }
  
      &__image {
        width: 100%;
        height: 60% !important;
  
        img {
          //object-fit: cover;
        }
      }
    }
  }
  