.prehome-home {
    $tagHeight: 86px;
    overflow-y: hidden !important;

    &__hero {
        display: grid;
        place-items: center;
        height: calc(100vh - (#{$header-top-height} * 2.5));
        //Background image properties
        background-color: $color-primario;
        background-image: url(../../images/tower_icon.png);
        background-size: contain;
        background-position: right bottom;
        background-repeat: no-repeat;
        @include image-2x('../../images/tower_icon@2x.png', contain);
        @include image-3x('../../images/tower_icon@3x.png', contain);
        background-blend-mode: multiply;

        .titles {
            text-align: center;
            color: $color-blanco;
            h1 {
                @include font-size(51px);
                font-weight: 400;
                margin-bottom: 0.7rem;
                padding: 0 1rem;

                @media (min-width: $md) {
                    @include font-size(70px);
                    margin-bottom: 1rem;
                }

                strong {
                    color: $color-secundario;
                }
            }
            h2 {
                @include font-size(17px);
                font-weight: 300;
                margin: 0 auto;
                padding: 0 1.5rem;

                @media (min-width: $sm) {
                    @include font-size(24px);
                }
            }
        }

        @media only screen and (max-width: 767px) and (orientation: landscape) {
            height: 100vh;
        }
    }

    &__column {
        position: relative;
        background-color: $color-p-text;

        .inner-content {
            display: flex;
            flex-direction: column;
            color: $color-blanco;
            position: absolute;
            top: $tagHeight;
            height: calc(100% - #{$tagHeight});
            padding: 6vw;

            h3 {
                @include font-size(37px);
                font-weight: 400;
                margin-bottom: 22px;
            }
            p {
                @include font-size(17px);
                font-weight: 300;
            }
            .content-link {
                align-self: flex-end;
                margin-top: auto;
            }
            @media (min-width: $mobileMax) {
                padding: 10vw;
            }
            @media (min-width: $sm) {
                padding: 20vw 10vw 30vw 10vw;

                .content-link {
                    transform: scale(2) translate(-25%, -25%);
                }
            }
        }

        .bg-img {
            img {
                display: block;
                width: 100%;
            }
        }
        .color-tag {
            display: grid;
            place-items: center;
            height: $tagHeight;
            width: 100%;

            svg {
                width: 125px;
            }

            &.v-uno {
                background-color: $color-variante-uno;
            }
            &.v-dos {
                background-color: $color-variante-dos;
            }
            &.v-tres {
                background-color: $color-variante-tres;
            }
            &.v-cuatro {
                background-color: $color-variante-cuatro;
            }
        }
    }

    @include media(tablet-l-only) {
        &__hero {
            min-height: 100vh;
        }
    }

    //----- 1200px -----//
    @include media(desktop-m) {
        display: flex;
        &__hero {
            display: none;
        }
        &__column {
            display: flex;
            flex-direction: column-reverse;
            width: 25%;
            height: 100vh;
            overflow: hidden;
            transition: 650ms width ease-in-out;
            cursor: pointer;
            will-change: width;

            @media (prefers-reduced-motion) {
                transition: none;
            }

            /* ANIMACIONES DE COLUMNAS */
            /* SE MANEJA TODA LA ANIMACION DESDE EL :hover */

            &:hover,
            &:focus {
                transition-delay: 300ms;
                width: 75%;

                .inner-content {
                    animation: go-up-inner 650ms forwards;
                    animation-timing-function: ease-in-out;
                    animation-delay: 600ms;

                    @keyframes go-up-inner {
                        from {
                            height: 0;
                            padding: 4rem 3rem 0;
                        }
                        to {
                            height: calc(100vh - 30vh);
                            padding: 4rem 2rem 2rem;
                        }
                    }
                    @media (min-width: $xlg2) {
                        @keyframes go-up-inner {
                            from {
                                height: 0;
                                padding: 4rem 3rem 0;
                            }
                            to {
                                height: calc(100vh - 30vh);
                                padding: 4rem 3rem;
                            }
                        }
                    }
                    h3,
                    p {
                        transition: ease-in 1s;
                        transition-delay: 700ms;
                        opacity: 1;
                    }
                }
                .color-tag {
                    animation: go-up-tag 750ms forwards;
                    animation-timing-function: ease-in-out;
                    animation-delay: 600ms;

                    @keyframes go-up-tag {
                        from {
                            transform: translateY(0);
                        }
                        to {
                            transform: translateY(calc(-100vh + 30vh));
                        }
                    }
                }

                //Si el usuario no prefiere animaciones, cancelamos todo, solo pasamos propiedades finales sin transicion.
                @media (prefers-reduced-motion) {
                    transition: none;

                    .inner-content {
                        animation: none;
                        height: calc(100vh - 30vh);
                        padding: 4rem 2rem 2rem;

                        @include media(desktop-xl) {
                            padding: 4rem 3rem;
                        }

                        h3,
                        p {
                            transition: none;
                            opacity: 1;
                        }
                    }
                    .color-tag {
                        animation: none;
                        transform: translateY(calc(-100vh + 30vh));
                    }
                }
            }

            .inner-content {
                background-color: rgba(0, 0, 0, 0.5);
                height: 0;
                padding: 0;
                top: auto;
                bottom: 0;
                will-change: contents;

                h3,
                p {
                    opacity: 0;
                }
                h3 {
                    @include font-size(31px);
                }
                p {
                    @include font-size(15px);
                }
                .content-link {
                    transform: scale(1) translate(-25%, -25%);
                    transition: 400ms;

                    &:hover {
                        transform: scale(1.25) translate(-20%, -20%);
                    }
                }
            }
            .bg-img {
                height: 100%;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .color-tag {
                justify-content: center;
                position: absolute;
                z-index: 2;

                svg {
                    width: 100px;
                }
            }
        }
    }

    @include media(desktop-xl) {
        &__column {
            .inner-content {
                h3 {
                    @include font-size(33px);
                }
                p {
                    @include font-size(17px);
                }
                .content-link {
                    transform: scale(1.5) translate(-25%, -25%);

                    &:hover {
                        transform: scale(1.75) translate(-20%, -20%);
                    }
                }
            }
        }
    }
}
