.footer {
    position: relative;
    z-index: 96;
    width: 100%;
    background-color: $color-primario;
    overflow: hidden;

    @include media(desktop-m) {
        background-color: $color-oscuro;
    }
}

.footer__container {
    padding: 50px $space_x2;
    margin: 0 auto;
    grid-row-gap: $space_x2;
    width: 100%;
    color: $color-variante-cinco;
    @include font-size(14px);
    @include display-grid;
    @include grid-template-columns(auto);

    &__column1 {
        img {
            margin-bottom: $space_x3;
            width: 130px;
            height: auto;
        }
        p {
            margin-bottom: $space_x2;
        }

        p,
        a {
            display: flex;
            align-items: center;
            font-weight: 400;
            letter-spacing: 0.28px;
        }

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
                margin-bottom: $space;
                text-transform: none;
                text-decoration: none;
                color: $color-claro;

                img {
                    margin: 0px $space 0 0;
                    width: 10px;
                    height: 100%;
                }

                &:hover {
                    color: $color-secundario;
                }
            }
        }

        &__social {
            margin-top: $space_x3;
            width: calc((29px * 4) + (12px * 3));
            max-width: calc((29px * 4) + (12px * 3));

            a {
                transform: scale(0.8);

                &:hover {
                    transform: scale(0.9);
                }
            }
        }
    }

    &__column2,
    &__column3 {
        display: none;
    }

    &__column2,
    &__column3,
    &__column4 {
        h3 {
            margin-bottom: $space;
            letter-spacing: 0.8px;
            font-weight: 400;
            @include font-size(16px);
            display: none;
        }

        ul {
            padding: 0;
            list-style: none;
            li {
                margin: $space_half 0;
                @include font-size(14px);
            }

            a {
                font-weight: 100;
                text-decoration: none;
                letter-spacing: 0.8px;
                color: $color-claro;
                &:hover {
                    color: $color-secundario;
                }
            }

            a.active {
                color: $color-secundario;
            }
        }

        p {
            font-weight: 400;
            text-decoration: none;
            letter-spacing: 0.8px;
            color: $color-claro;
            margin-bottom: $space_x2;
        }

        &-input {
            margin-bottom: $space_x2;
            max-width: 320px;
        }
    }

    &__column5 {
        display: flex;
        flex-direction: row;

        img {
            margin-right: $space_x3;
            width: 71px;
            height: 97px;
        }

        p {
            font-weight: 400;
            text-decoration: none;
            letter-spacing: 0.8px;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -o-pre-wrap;
            color: $color-claro;
            @include font-size(12px);
        }
    }

    &__footer {
        position: relative;
        padding-top: $space_x5;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-top: $space_x4;

        &::before {
            content: '';
            opacity: 0.5;
            height: 0.5px;
            background-color: get-opacity($color-claro, 0.1);
            position: absolute;
            top: 0px;
            left: -500px;
            right: -500px;
        }

        p,
        span {
            font-weight: 100;
            text-decoration: none;
            letter-spacing: 0.8px;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -o-pre-wrap;
            color: $color-claro;
            @include font-size(12px);
        }

        nav {
            display: none;
        }

        span {
            color: $color-secundario;
        }
    }

    @include media(tablet) {
        padding: $space_x4;
        width: 80%;
        margin: 0 auto;

        &__column2,
        &__column3,
        &__column4 {
            h3 {
                display: block;
            }
            p {
                text-align: left;
            }
        }

        &__column4 {
            &-input {
                max-width: 420px;
            }
        }
    }

    @include media(desktop-s) {
        padding: 80px $space_x2;
        grid-gap: $space_x3;
        width: 100%;
        max-width: 100%;
        @include font-size(13px);
        @include grid-template-columns(27% 1fr 1fr 1fr);

        &__column2,
        &__column3,
        &__column4 {
            display: block;
            margin: 0;
        }

        &__column5 {
            margin-left: 0;

            img {
                margin-right: $space;
            }

            p {
                margin: 0;
            }
        }

        &__footer {
            padding-top: 60px;
            grid-column: 1 / span 2;
            text-align: left;
        }
    }

    @include media(desktop-m) {
        padding: 75px 0px $space_x6 0px;
        grid-gap: $space_x8 $space_x3;
        justify-content: space-between;
        max-width: 72%;
        @include font-size(12px);
        @include grid-template-columns(22% max-content max-content 20% 20%);

        &__column1,
        &__column2,
        &__column3,
        &__column4,
        &__column5 {
            h3 {
                color: $color-variante-cinco;
            }

            p, span, a {
                color: get-opacity($color-variante-cinco, 0.8);
            }

            li a {
                color: get-opacity($color-variante-cinco, 0.8);
            }
        }        

        &__column1 {
            img {
                width: 50%;
            }

            a {
                margin-bottom: 15px;
                @include font-size(11.5px);
            }

            p {
                @include font-size(11.5px);
            }
        }

        &__column2,
        &__column3,
        &__column4 {
            display: block;
            margin: 0;

            h3 {
                @include font-size(12.5px);
            }

            a {
                @include font-size(11.5px);
            }

            p {
                @include font-size(11.5px);
            }
        }

        &__column5 {
            margin-left: $space_x2;

            img {
                margin-right: $space_x2;
                width: 50px;
                height: 70px;
            }

            p {
                @include font-size(8px);
            }
        }

        &__footer {
            grid-column: 1 / span 5;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0px;

            nav {
                display: flex;

                .footer-nav-link {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include font-size(13px);
                    font-weight: 300;
                    color: get-opacity($color-variante-cinco, 0.3);

                    &:not(:last-child) {
                        margin-right: 3rem;
                    }

                    &.active {
                        color: $color-secundario;
                    }
                }
            }

            p {
                color: get-opacity($color-variante-cinco, 0.8);
            }
        }
    }

    @include media(desktop-xl) {
        padding: 100px 0px;
        max-width: 80%;
        @include font-size(15px);

        &__column5 {
            img {
                margin-right: $space_x3;
                width: 71px;
                height: 97px;
            }
        }
    }

    @include media(desktop-xxl) {
        max-width: 80%;
    }

    @include media(desktop-xxxl) {
        max-width: 90%;
        grid-gap: $space_x8 $space_x6;
        //

        &__column5 {
            justify-content: flex-end;

            img {
                margin-right: 1.5rem;
            }
        }
    }
}
